import React from 'react';
import $ from 'jquery';
import gsap from 'gsap';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import feather from 'feather-icons';
import { Link } from 'react-router-dom';

import Map from 'components/Map';

let inputTitles = [
  'What is your name?',
  'What is your email?',
  'What can we help you with?'
];

class Contact extends React.Component {
  state = {
    screenActive: false,
    error: undefined,
    contactName: '',
    contactEmail: '',
    contactMessage: '',
    step: 0,
    formComplete: false,
    mapOption: 0,
    inputTitles,
    address: {
      toronto: [
        window.hc.content.main.address.line_1_toronto,
        window.hc.content.main.address.line_2_toronto
      ],
      ottawa: [
        window.hc.content.main.address.line_1_ottawa,
        window.hc.content.main.address.line_2_ottawa
      ]
    }
  }

  componentDidMount() {
    window.hc.screen.updateIndex(5)

    feather.replace()

    this.listeners()

    setTimeout(() => { this.animate() }, 4000) // animate if map still not loaded after a while

    const toggleContent = () => {

      let isEnglish = this.props.i18n.language === 'en'

      let newInput = isEnglish ? [
        window.hc.content.main.question_one,
        window.hc.content.main.question_two,
        window.hc.content.main.question_three
      ] : [
        window.hc.content.main.question_one_fr,
        window.hc.content.main.question_two_fr,
        window.hc.content.main.question_three_fr
      ]
      let newAddress = isEnglish ? {
        toronto: [
          window.hc.content.main.address.line_1_toronto,
          window.hc.content.main.address.line_2_toronto
        ],
        ottawa: [
          window.hc.content.main.address.line_1_ottawa,
          window.hc.content.main.address.line_2_ottawa
        ]
      } : {
        toronto: [
          window.hc.content.main.address_fr.line_1_toronto,
          window.hc.content.main.address_fr.line_2_toronto
        ],
        ottawa: [
          window.hc.content.main.address_fr.line_1_ottawa,
          window.hc.content.main.address_fr.line_2_ottawa
        ]
      }
      this.setState({ inputTitles : newInput, address: { toronto: [...newAddress.toronto], ottawa: [...newAddress.ottawa]} });
    }

    toggleContent();
    this.props.i18n.on('languageChanged', (lng) => {
      toggleContent();
    })
  }

  componentDidUpdate(prevProps, prevState) {

    if(this.state.step !== prevState.step) {

      const inputMessage = document.querySelector('.contactcontainer .form h5')

      if(!inputMessage) return

      gsap.to(inputMessage, 0.2, {
        opacity: 0,
        onComplete: () => {
          inputMessage.innerHTML = this.state.inputTitles[this.state.step]
          gsap.to(inputMessage, 0.2, { opacity: 1 })
        }
      })

    }

  }

  listeners() {

    const getThis = () => this // scoped

    $(document).on('keypress', function(e) {

      switch(e.which) {
        case 13:

        const that = getThis()
        const step = that.state.step

        if(step < 2)        that.setState({ step: step + 1 });
        else if(step === 2) that.send_message()

        break;
        default: return;
      }

    });

  }

  animate() {

    // map
    gsap.to(document.querySelector('.contactcontainer .container .map'), 0.6, {
      opacity: 1
    })

    gsap.to(document.querySelector('.contactcontainer .container .mapcover'), 0.6, {
      backdropFilter: 'blur(0)',
      backgroundColor: 'rgba(5,5,5,0)',
      onComplete: () => { $('.mapcover').fadeOut() } // tweening backdropfilter doesn't work on Safari, so just fade out element
    })

    // form
    gsap.to(document.querySelector('.contactcontainer .container .form h1'), 0.4, { opacity: 1, transform: 'translateY(0)', delay: 0 })
    gsap.to(document.querySelector('.contactcontainer .container .form h5'), 0.4, { opacity: 1, transform: 'translateY(0)', delay: 0.2 })

    gsap.to(document.querySelector('.contactcontainer .container .form .inputs'), 0.4, { opacity: 1, delay: 0.4 })
    gsap.to(document.querySelector('.contactcontainer .container .form .inputs'), 1.4, {
      width: '100%',
      delay: 0.2, // start a bit earlier than opacity change
      ease: "power3",
      onComplete: () => {
        gsap.to(document.querySelector('.contactcontainer .container .form .buttons'), 0.4, { opacity: 1, transform: 'translate(0, -10px)' })
      }
    })

    gsap.to(document.querySelector('#social'), 0.4, { opacity: 1, delay: 0.6 })

    // info
    document.querySelectorAll('.info #cities, .info #details h3').forEach((item, i) => {

      gsap.to(item, 0.4, {
        opacity: 1,
        transform: 'translateY(0)',
        delay: 0.06 * i + 0.6,
        onComplete: () => {
          setTimeout(() => {
            if(window.hc.screenIndex !== 4) return
            document.querySelector('#cities h2').classList.add('active')
          }, 400)
        }
      })

    })

  }

  toggleLocation(option) {

    const cityNames = $('#cities h2')
    const cityDetails = $('.contactcontainer .info #details')

    cityNames.removeClass('active')

    this.setState({ mapOption: option })

    switch(option) {
      case 0:
        cityNames[0].classList.add('active')
        cityDetails.find('.one a')[0].innerHTML = this.state.address.toronto[0]
        cityDetails.find('.one a')[1].innerHTML = this.state.address.toronto[1]

        if(window.hc.map) window.hc.map.setCenter('toronto')
      break;

      case 1:
        cityNames[1].classList.add('active')

        cityDetails.find('.one a')[0].innerHTML = this.state.address.ottawa[0]
        cityDetails.find('.one a')[1].innerHTML = this.state.address.ottawa[1]

        if(window.hc.map) window.hc.map.setCenter('ottawa')
      break;

      default: console.warn(`Parameter not valid: ${option}`)
    }

  }

  async send_message() {

    if(this.state.sending) return;
    this.setState({ sending: true });

    try{

      const { contactName, contactEmail, contactMessage } = this.state

      const submission = {
      	"name": contactName,
      	"email": contactEmail,
      	"message": contactMessage,
        "recipent": window.hc.content.main.contact_recipent || "contact@humancontact.com"
      }

      const url = 'https://mailer.humancontact.com/'

      console.log(submission);

      const req = await fetch(url, {
        method: 'POST',
        crossDomain: true,
        mode: 'cors',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(submission)
      })

      const res = await req.json()

      console.log(res);

      this.setState({ formComplete: true, sending: false })

    }

    catch(e) {
      console.error(e)
      if (this.props.i18n.language === 'en') {
        alert('Sorry! There was an issue with your submission. Please email us at contact@humancontact.com. ')
      } else {
        alert('Désolé ! Il y a eu un problème avec votre soumission. Veuillez nous envoyer un courriel à contact@humancontact.com. ')
      }
      this.setState({ sending: false });
    }

  }

  onHoverAddress(hovering) {

    hovering ?
      $('.info #details .one').addClass('active') :
      $('.info #details .one').removeClass('active')

  }

  render_inputs() {

    const { step, contactName, contactEmail, contactMessage } = this.state;

    // prevent white space / margin at the bottom on mobile keyboard out
    const onBlur = () => {
      if(window.hc.device.isMobile) window.scrollTo(0,0)
    }

    const inputs = {
      0: <input autoComplete="off" value={contactName} onBlur={onBlur} onChange={(e) => { this.setState({ contactName: e.target.value }) }} />,
      1: <input autoComplete="nosuggestion" value={contactEmail} onBlur={onBlur} onChange={(e) => { this.setState({ contactEmail: e.target.value }) }} />,
      2: <input autoComplete="off" value={contactMessage} onBlur={onBlur} onChange={(e) => { this.setState({ contactMessage: e.target.value }) }} />
    }

    return inputs[step];
  }

  render_buttons() {

    const { contactName, contactEmail, contactMessage, step } = this.state;

    if(contactName && contactEmail && contactMessage)
      return <div className="send" onClick={() => { this.send_message() }}>Send</div>

    else return (
      <div>
        <div id="left"
             onClick={() => { if(step >= 1) this.setState({ step: step - 1 }) }}
             style={{ opacity: step === 0 ? 0 : 1 }}>
          <img src={require('assets/images/arrow_right.svg')} alt="left arrow" />
          <div className="line"></div>
        </div>
        <div id="right"
             onClick={() => { if(step <= 1) this.setState({ step: step + 1 }) }}
             style={{ opacity: step === 2 ? 0 : 1 }}>
          <div className="line"></div>
          <img src={require('assets/images/arrow_right.svg')} alt="right arrow" />
        </div>
      </div>
    )

  }

  render_form() {
    const { error, contactName, formComplete } = this.state;
    // const { i18n, t } = this.props;

    const el_thanks = (
      <div className="thankyou">
        <h1>Thank you for reaching out, {contactName}!</h1>
        <div>
          <h5>We will get back to you as soon as possible.</h5>
        </div>
      </div>
    )

    const el_form = (
      <div>
        <h1>{this.props.i18n.language === 'en' ? window.hc.content.main.form_message : window.hc.content.main.form_message_fr}</h1>
        <div>
          <h5>{this.state.inputTitles[0]}</h5>
          <div className="inputs">
            <div id="inputcontainer">{this.render_inputs()}</div>

            <div className="buttons">
              {this.render_buttons()}
            </div>

            <div className="errors">
              <h4>{error}</h4>
            </div>
          </div>

        </div>
      </div>
    )

    return (
      <div className="form">
        {formComplete ? el_thanks : el_form }

        <div className="leaf-canvas" style={{
            // display: formComplete ? 'block' : 'none',
            display: 'none',
            transform: 'translate(-25%, 5%)'
          }}>
          <canvas id="stringCanvas"></canvas>
        </div>
      </div>
    )

  }

  render() {

    const companyLocation = this.state.mapOption === 1 ?
      encodeURI(this.state.address.ottawa[0] + this.state.address.ottawa[1]) :
      encodeURI(this.state.address.toronto[0] + this.state.address.toronto[1])

    const companyAddress = `https://www.google.com/maps/search/?api=1&query=${companyLocation}`

    const { t } = this.props;

    return (
      <div className={"contactcontainer" + (window.hc.device.isMobile ? ' ismobile' : '')}>

        <Helmet>
          <title>HUMANCONTACT | Contact Us</title>
          <meta name="HUMANCONTACT" content="Contact Us | Email: contact@humancontact.com | Phone: 416-361-0113" />
        </Helmet>

        <div id="social">
          <a href="https://www.instagram.com/humancontactinc/?hl=en" target="_blank" rel="noopener noreferrer"><div><img id="instagram" src={require('assets/images/icons/instagram.svg')} alt="instagram icon"></img></div></a>
          <a href="https://ca.linkedin.com/company/humancontact" target="_blank" rel="noopener noreferrer"><div><img src={require('assets/images/icons/linkedin.svg')} alt="linkedin icon"></img></div></a>
        </div>

        <div className="container">

          <div className="map">
            <div className="mapcover"></div>
            <Map onMapLoad={() => {
                setTimeout(() => { this.animate() }, 1000)
              }} />
          </div>

          {this.render_form()}

          <div className="info">
            <div id="cities">
              <h2 onClick={() => { this.toggleLocation(0) }}>TORONTO</h2>
              <h2 onClick={() => { this.toggleLocation(1) }}>OTTAWA</h2>
            </div>
            <div id="details">
              <h3 className="one"
                  onMouseEnter={() => { this.onHoverAddress(true) }}
                  onMouseLeave={() => { this.onHoverAddress(false) }}>
                <a href={companyAddress} target="_blank" rel="noopener noreferrer">{this.state.address.toronto[0]}</a>
              </h3>
              <h3 className="one"
                  onMouseEnter={() => { this.onHoverAddress(true) }}
                  onMouseLeave={() => { this.onHoverAddress(false) }}>
                <a href={companyAddress} target="_blank" rel="noopener noreferrer">{this.state.address.toronto[1]}</a>
              </h3>
              <h3 className="two"><a href="mailto:contact@humancontact.com">E: CONTACT@HUMANCONTACT.COM</a></h3>
              <h3 className="two"><a href="tel:4163610113">P: 416.361.0113</a></h3>
              <div id="terms-and-privacy">
                <h3 className="two"><Link to='/terms'>{t('Terms And Conditions')}</Link></h3>
              </div>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default withTranslation()(Contact);
